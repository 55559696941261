
import ModalUtils from '@/scripts/modal'
import { Options, Vue, prop } from 'vue-class-component'
import StoragePlans from './StoragePlans.vue'

import { YTabs, YTabItem } from '@pacprotocol/yanui'

class Props {
    size = prop({
        required: false,
        default: 176,
    })
}

@Options({
    components: {
        StoragePlans,
        YTabs,
        YTabItem,
    },
})
export default class StorageOptions extends Vue.with(Props) {
    private plans: any = []
    private recurring: string = 'month'
    private highest_saving = 0
    async created() {
        this.plans = await this.$utils.get_plans()
        this.plans.unshift({
            name: 'Free',
            product_id: 'free',
            storage: -1,
            free: true,
        })
        this.highest_saved_percentage_update();
    }

    async select_recurring(recurring: string) {
        const r = recurring.toLowerCase()

        if (r.startsWith('monthly')) {
            this.recurring = 'month'
        } else if (r.startsWith('yearly')) {
            this.recurring = 'year'
        }
        console.log(this.recurring, r)
    }

    private highest_saved_percentage_update() {
        let savings = 0
        for (let i = 0; i < this.plans.length; i++) {
            if (this.plans[i]?.free) continue
            const monthly = this.plans[i].prices['month'].amount
            const yearly = this.plans[i].prices['year'].amount
            const diff = monthly * 12 - yearly
            const saving = (diff / (monthly * 12)) * 100
            const _savings = Math.round(saving)
            if (_savings > savings) {
                savings = _savings
            }
        }
        this.highest_saving = savings
        //@ts-ignore
        this.$refs.planTabs.forceUpdate();
    }
}
