import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "plan-button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PlanRaw = _resolveComponent("PlanRaw")!
  const _component_y_button = _resolveComponent("y-button")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["storage-plan-component card center", { free: _ctx.plan.free, recommended: _ctx.plan.recommended }])
  }, [
    _createVNode(_component_PlanRaw, {
      plan: _ctx.plan,
      recurring: _ctx.recurring
    }, null, 8, ["plan", "recurring"]),
    _createElementVNode("div", _hoisted_1, [
      (!_ctx.plan.free)
        ? (_openBlock(), _createBlock(_component_y_button, {
            key: 0,
            type: "is-success",
            onClick: _ctx.choose
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Choose ")
            ]),
            _: 1
          }, 8, ["onClick"]))
        : (_openBlock(), _createBlock(_component_y_button, {
            key: 1,
            type: "is-ghost"
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Current Plan ")
            ]),
            _: 1
          }))
    ])
  ], 2))
}