import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "legal-text-component-icon"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }
const _hoisted_5 = {
  key: 3,
  class: "inline colored",
  href: "/terms-of-service",
  target: "_blank"
}
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 4 }
const _hoisted_8 = {
  key: 5,
  class: "inline colored",
  href: "/privacy-policy",
  target: "_blank"
}
const _hoisted_9 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa = _resolveComponent("fa")!

  return (_openBlock(), _createElementBlock("label", {
    class: _normalizeClass(["legal-text-component", { 'is-checkbox': _ctx.is_checkbox, alert: _ctx.alert }])
  }, [
    (_ctx.is_checkbox)
      ? (_openBlock(), _createElementBlock("input", {
          key: 0,
          class: "checkbox",
          type: "checkbox",
          onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.checked)))
        }, null, 32))
      : _createCommentVNode("", true),
    (!_ctx.is_checkbox)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_fa, {
            icon: ['fal', 'info-circle'],
            size: "3x"
          })
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", null, [
      (_ctx.is_checkbox)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, "By ticking this box "))
        : (_openBlock(), _createElementBlock("span", _hoisted_3)),
      _createTextVNode("I agree that I have read the "),
      (_ctx.updated)
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, "updated "))
        : _createCommentVNode("", true),
      (_ctx.terms_of_service)
        ? (_openBlock(), _createElementBlock("a", _hoisted_5, [
            _createTextVNode("Terms of Service"),
            (!_ctx.privacy_policy)
              ? (_openBlock(), _createElementBlock("span", _hoisted_6, "."))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (_ctx.privacy_policy && _ctx.terms_of_service)
        ? (_openBlock(), _createElementBlock("span", _hoisted_7, " and "))
        : _createCommentVNode("", true),
      (_ctx.privacy_policy)
        ? (_openBlock(), _createElementBlock("a", _hoisted_8, [
            _createTextVNode("Privacy Policy"),
            (_ctx.terms_of_service)
              ? (_openBlock(), _createElementBlock("span", _hoisted_9, "."))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}