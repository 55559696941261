
import ModalUtils from '@/scripts/modal'
import { Utils, YButton } from '@pacprotocol/yanui'
import { Options, Vue, prop } from 'vue-class-component'
import PlanRaw from "./PlanRaw.vue"


class Props {
    plan: any = prop({
        required: true,
    })
    recurring = prop({
        required: false,
        default: 'month',
    })
}

@Options({
    components: {
        YButton,
        PlanRaw
    },
})
export default class StoragePlan extends Vue.with(Props) {
    get price() {
        return this.plan.prices[this.recurring]
    }
    get amount() {
        return Math.round(this.price.amount) / 100
    }
    get recurring_text() {
        return Utils.capitalize_first_letter_sentence(this.recurring)
    }
    get saved_percentage() {
        //Calculate Saving in Percentage with Yearly / Monthly difference (2 month free)
        const monthly = this.plan.prices['month'].amount
        const yearly = this.plan.prices['year'].amount
        const diff = monthly * 12 - yearly
        const saving = (diff / (monthly * 12)) * 100
        return Math.round(saving)
    }

    private choose() {
       // ModalUtils.open_payment(this, {
       //     button_text: 'Add Card',
       //     plan: this.plan,
       // })
       //console.log()
       this.$utils.subscription_create_checkout_session(this.plan.prices[this.recurring].id)
    }

    get current_plan() {
        return this.$store.state?.user?.plan
    }

    get current_storage() {
        if (this.current_plan) {
            return Number(this.current_plan.metadata.storage)
        } else {
            return 0
        }
    }

    get is_downgradeable() {
        // Check if the current_plan price is higher than the plan
        return this.current_storage > this.plan.storage
    }

    get is_upgradeable() {
        // Check if the current_plan price is lower than the plan
        return this.current_storage < this.plan.storage
    }

    get is_current_plan() {
        if (!this.current_plan) return false
        return this.current_plan.id === this.plan.product_id
    }
}
