
import { Options, Vue, prop } from 'vue-class-component'

class Props {
    privacy_policy = prop({
        required: false,
        default: false,
    })
    terms_of_service = prop({
        required: false,
        default: false,
    })
    updated = prop({
        required: false,
        default: false,
    })
    is_checkbox = prop({
        required: false,
        default: false,
    })
    modelValue = prop({
        required: false,
    })
    alert = prop({
        type: Boolean,
        required: false,
        default: false,
    })
}

@Options({
    components: {},
})
export default class TermsOfService extends Vue.with(Props) {
    async created() {
        //
    }
}
