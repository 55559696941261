import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "storage-options-component" }
const _hoisted_2 = { class: "storage-options-plans" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_y_tab_item = _resolveComponent("y-tab-item")!
  const _component_y_tabs = _resolveComponent("y-tabs")!
  const _component_StoragePlans = _resolveComponent("StoragePlans")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_y_tabs, {
      ref: "planTabs",
      ghost: true,
      "full-width": true,
      onClick: _cache[0] || (_cache[0] = (e) =>_ctx.select_recurring(e.title))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_y_tab_item, { title: "Monthly" }),
        _createVNode(_component_y_tab_item, {
          title: `Yearly (Save up to -${_ctx.highest_saving}%)`
        }, null, 8, ["title"])
      ]),
      _: 1
    }, 512),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.plans, (plan) => {
        return (_openBlock(), _createBlock(_component_StoragePlans, {
          key: plan.product_id,
          plan: plan,
          recurring: _ctx.recurring
        }, null, 8, ["plan", "recurring"]))
      }), 128))
    ])
  ]))
}