
import DashboardLayout from '@/layouts/DashboardLayout.vue'
import { Options, Vue, prop } from 'vue-class-component'
import FileViewer from '@/components/FileViewer.vue'
import { FileEntity } from '@pacprotocol/yanui'

@Options({
    components: {
        FileViewer,
    },
})
export default class FilesView extends Vue {
    async on_file_remove(file: FileEntity, el: HTMLElement, index: number) {
        this.$store.state.files = this.$store.state.files.filter(
            (f) => f.id !== file.id,
        )
    }
}
