
import { Options, Vue } from 'vue-class-component'


import Avatar from '@/components/Avatar.vue'

import {
    Utils,
    YButton,
    YTabs,
    YTabItem,
    YForm,
    YProgressBar,
    YInputText,
} from '@pacprotocol/yanui'
import StorageOptions from '@/components/Payments/StorageOptions.vue'

@Options({
    components: {
        YTabs,
        YTabItem,
        YButton,
        YForm,
        YInputText,
        YProgressBar,
        Avatar,
        StorageOptions,
    },
})
export default class Settings extends Vue {
    private original_settings: any = {}
    private settings: any = {}
    private error: any = {}
    private avatar_uploading: boolean = false

    async created() {
        //
    }

    async upload_avatar() {
        if (!this.avatar_uploading) {
            const file = await (this.$i
                ? this.$i
                : this
            ).$yanui.file_utils.browse_files(
                'image/png, image/jpeg',
                'file',
                false,
            )
            if (this.$store.state.user.avatar_id) {
                const deleted = await this.$utils.file_delete(
                    this.$store.state.user.avatar_id,
                    false
                )
                if (deleted) {
                    this.$yanui.db.user
                        .findOne({ selector: { id: this.$store.state.user.id } })
                        .update({ $set: { avatar_id: null } })
                }
            }
            this.avatar_uploading = true
            console.log(this.$yanui.image.resize);
            //Resize Avatar to 180x180
            const resized_file = await this.$yanui.image.resize(file[0], {
                width: 180,
                height: 180,
            })
            let id = await (this.$i ? this.$i : this).$utils.file_generate_id();
            let sha256_id = await (this.$i ? this.$i : this).$utils.sha256(id);
            try {
                await (this.$i ? this.$i : this).$utils.file_upload(
                    resized_file,
                    await (this.$i ? this.$i : this).$masterkey.derived_password_file(
                        'avatar-' + (this.$i ? this.$i : this).$auth0.user.value.sub.split('|')[1],
                    ),
                    {
                        id: sha256_id,
                    },
                    { 
                        on_upload_progress: (progress: number) => {
                            //Upload Progress
                        },
                    },
                )

                if (id) {
                    await this.$yanui.db.user
                        .findOne()
                        .update({
                            $set: {
                                avatar_id: id,
                                avatar_id_sha256: sha256_id
                            }
                        })
                }
                (this.$i ? this.$i : this).$utils.database_upload_trigger(0)
                this.avatar_uploading = false
                ;(this.$i ? this.$i : this).$plausible.trackEvent(
                    'Settings Saved',
                    { props: { settings_changed: 'avatar' } },
                )
            } catch (e) {
                console.error(e)
                this.$yanui.toast.show('Uploading Avatar Failed!', {
                    type: 'is-danger',
                })
                this.avatar_uploading = false
            }
        }
    }

    save() {
        const diff = Utils.diff(this.original_settings, this.settings)
        if (!diff.password) {
            delete diff.password
        }
        if (!diff.current_password) {
            delete diff.current_password
        }
        if (
            //@ts-ignore
            (!diff.password || this.$refs.passref.validate()) &&
            Object.keys(diff).length > 0
        ) {
            this.$yanid
                .change_settings(diff)
                .then(async (body: any) => {
                    const { settings, check_email } = body
                    if (check_email) {
                        this.$yanui.toast.show(
                            'Please confirm your E-Mail\nto apply required changes!',
                            {
                                type: 'is-warning',
                            },
                        )
                    } else {
                        this.$yanui.toast.show('Changes Updated!', {
                            type: 'is-success',
                        })
                    }
                    this.original_settings = settings
                    this.settings = JSON.parse(JSON.stringify(settings))
                    this.settings.password = ''
                    this.settings.current_password = ''
                    const [err, profile] = await this.$yanui.utils.to(
                        this.$yanid.user(),
                    )
                    this.$store.commit('profile', profile)
                    const settings_saved = Object.keys(diff)
                    for (let i = 0; i < settings_saved.length; i++) {
                        ;(this.$i ? this.$i : this).$plausible.trackEvent(
                            'Settings Saved',
                            { props: { settings_changed: settings_saved[i] } },
                        )
                    }
                })
                .catch((e) => {
                    if (e.statusCode === 400) {
                        this.error = e
                    }
                    console.error(e.message)
                    this.$yanui.toast.show(
                        Array.isArray(e.message)
                            ? e.message.join('\n')
                            : e.message,
                        {
                            type: 'is-danger',
                        },
                    )
                })
        } else {
            this.$yanui.toast.show('No changes!')
        }
    }
}
