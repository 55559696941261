
import { Options, Vue, prop } from 'vue-class-component'
import LegalText from '@/components/Legal/LegalText.vue'
import { YModal } from '@pacprotocol/yanui'

@Options({
    components: {},
})
export default class LegalAgreement extends Vue {
    async mounted() {
        this.legal_consent_allowed()
    }

    async legal_consent_allowed() {
        if (!this.$store.state.user.id) {
            const [err, profile] = await this.$yanui.utils.to(
                this.$yandna.profile_get(),
            )
            if (!err) {
                this.$store.commit('profile', {
                    ...profile.profile,
                    ...profile.user,
                })
            }
        }

        const user = this.$store.state.user;
        const tos_consent = user.terms_of_services_consent_version
        const pp_consent = user.privacy_policy_consent_version
        console.log('USER', user, tos_consent, pp_consent)

        // eslint-disable-next-line no-constant-condition
        if (false/*tos_consent || pp_consent*/) {
            const title =
                (tos_consent ? 'Terms of Service' : '') +
                (tos_consent && pp_consent ? ' and ' : '') +
                (pp_consent ? 'Privacy Policy' : '') +
                ' Updated'

            const legal_consent_allowed = (): Promise<void> => {
                return new Promise((resolve) => {
                    this.$yanui.modal.open({
                        instance: this,
                        title,
                        overflow: 'auto',
                        component: LegalText,
                        maxWidth: '500px',
                        width: '500px',
                        props: {
                            privacy_policy: pp_consent,
                            terms_of_service: tos_consent,
                            updated: true,
                        },
                        canCancelBackground: false,
                        canCancel: false,
                        footer: {
                            buttons: [
                                {
                                    text: 'I Disagree',
                                    type: 'is-ghost',
                                    action: (modal: typeof YModal) => {
                                        ;(this.$i
                                            ? this.$i
                                            : this
                                        ).$plausible.trackEvent(
                                            'Legal Disagree Warn',
                                        )
                                        this.$yanui.dialog.alert({
                                            title: `Account & Files Termination`,
                                            message:
                                                'If you decline the Terms of Service & Privacy Policy, all of your account information and files will be permanently deleted in 30 days. Within those 30 days you can still recover your account by logging in again and agreeing to the Terms of Service.\n\nAfter permanent deletion, you will no longer be able to use this service.\n\nAre you sure about that?',
                                            icon: 'trash-alt',
                                            type: 'is-danger',
                                            maxWidth: '600px',
                                            buttons: [
                                                {
                                                    text: 'Abort',
                                                    type: 'is-danger',
                                                    action: (
                                                        alert_modal: typeof YModal,
                                                    ) => {
                                                        alert_modal.close()
                                                    },
                                                },
                                                {
                                                    text: 'Yes, I disagree!',
                                                    type: 'is-grey',
                                                    action: async (
                                                        alert_modal: typeof YModal,
                                                    ) => {
                                                        this.$plausible.trackEvent(
                                                            'Legal',
                                                            {
                                                                props: {
                                                                    action: 'disagree',
                                                                },
                                                            },
                                                        )
                                                        await this.$yandna.legal_disagree()
                                                        this.$utils.logout().finally(() => {
                                                            console.error(
                                                                '[LOGOUT]',
                                                                'success',
                                                            )
                                                            this.$router.replace({
                                                                name: 'Login',
                                                            })
                                                            alert_modal.close()
                                                            modal.close()
                                                        })
                                                    },
                                                },
                                            ],
                                        })
                                    },
                                },
                                {
                                    text: 'I Agree',
                                    type: 'is-success',
                                    action: async (modal: typeof YModal) => {
                                        this.$plausible.trackEvent('Legal', {
                                            props: { action: 'agree' },
                                        })
                                        await this.$yandna.legal_agree()
                                        if (
                                            this.$store.state.redirect &&
                                            this.$store.state.redirect.name
                                        ) {
                                            this.$router.push({
                                                name: this.$store.state.redirect
                                                    .name,
                                            })
                                            this.$store.state.redirect = null
                                        } else {
                                            this.$router.push({ name: 'Files' })
                                        }
                                        modal.close()
                                        resolve()
                                    },
                                },
                            ],
                        },
                    })
                })
            }
            return legal_consent_allowed()
        }
        return Promise.resolve()
    }
}
