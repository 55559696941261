import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "file-page full-height" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa = _resolveComponent("fa")!
  const _component_FileViewer = _resolveComponent("FileViewer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_FileViewer, {
      filter: { pin: true },
      sort: ['type:desc'],
      "ignore-parent": true,
      elements: [
                'upload',
                'view-dropdown',
            ]
    }, {
      emptyIcon: _withCtx(() => [
        _createVNode(_component_fa, { icon: ['fal', 'thumbtack'] })
      ]),
      emptyTitle: _withCtx(() => [
        _createTextVNode(" No Pinned Files! ")
      ]),
      emptySubtitle: _withCtx(() => [
        _createTextVNode(" Pin files to show up here. ")
      ]),
      _: 1
    })
  ]))
}