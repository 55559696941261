import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "file-page full-height" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FileViewer = _resolveComponent("FileViewer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_FileViewer, {
      filter: {},
      sort: [{updated_at: 'desc'}],
      elements: [
                'upload',
                'view-dropdown',
            ]
    })
  ]))
}